/*************** GLOBAL  ***************/
* {
  font-family: "Nunito Sans";
}
.color-txt {
  color: #a8906e !important;
}
*:focus-visible {
  outline: none;
}
.nav-link:focus-visible {
  box-shadow: none;
}
.relative {
  position: relative !important;
}
.pt-sec {
  padding-top: 150px;
}
.pb-sec {
  padding-bottom: 150px;
}
.mt-sec {
  margin-top: 150px;
}
.mb-sec {
  margin-bottom: 150px;
}
.sec-heading {
  font-size: 58px;
  font-weight: 300;
}
.sec-heading strong {
  font-weight: 700;
}
.external-links {
  color: #a8906e;
}
.external-links:hover {
  color: #a8906e;
}
.max-width-100 {
  max-width: 100% !important;
}
.max-width-740 {
  max-width: 740px !important;
}
/*************** HEADER  ***************/
header {
  position: relative;
  z-index: 99;
}
header nav {
  background-color: #1c191a;
  font-family: "Plus Jakarta Sans";
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
header .nav-link {
  color: #fff !important;
  font-weight: 500;
  font-size: 15px;
  font-family: "Plus Jakarta Sans";
  padding: 0 !important;
}
header .nav-link.active,
header .nav-link:hover {
  color: #a8906e !important;
}
.navbar-brand img {
  position: relative;
  top: -2px;
}
ul.navbar-nav {
  margin-left: 50px;
  width: 100%;
  max-width: 900px;
  justify-content: space-between;
}
.navbar-toggler {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  padding: 0 !important;
}
.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: #fff;
  margin: 6px 0;
  border-radius: 3px;
  transition: 0.4s;
}

.change .bar1 {
  transform: translate(0, 11px) rotate(-45deg);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  transform: translate(0, -11px) rotate(45deg);
}

/*************** HOME BANNER  ***************/
.banner-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 40px;
}

.banner-content h1 {
  font-size: 54px;
  color: #fff;
  font-weight: 300;
  max-width: 700px;
  line-height: 1.2;
}
.banner-content h1 strong {
  font-weight: 800;
}

.banner-content h3 {
  font-weight: 400;
  font-size: 23px;
  max-width: 510px;
  color: #fff;
  line-height: 1.2;
  margin-top: 18px;
}

.banner-btn {
  padding: 0;
  background-color: #fff;
  width: 150px;
  height: 44px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-weight: 600;
  margin-top: 50px;
  color: #1c191a;
  text-decoration: none;
}
/*************** PRODUCT SLIDER  ***************/

.products-sec h2 {
  font-weight: 300;
  color: #1c191a;
  text-transform: uppercase;
  max-width: 530px;
  margin: 0 auto;
}
.products-sec h2 strong {
  font-weight: 700;
  color: #a8906e;
}

.products-sec h3 {
  font-size: 26px;
  font-weight: 300;
  margin: 12px auto 65px;
  max-width: 830px;
  text-align: center;
}
.slider-item {
  position: relative;
}
.slider-item img {
  height: 425px;
  /* min-width: 360px; */
  object-fit: cover;
  width: 100% !important;
}
.product-name {
  position: absolute;
  color: #fff;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  text-align: center;
}
.slider-item a {
  /* min-width: 360px; */
  display: block;
}

.slider-item .slider-content-wrapper:before {
  content: "";
  display: block;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 13.81%, rgba(24, 24, 27, 0) 63.11%);
  position: absolute;
  left: 0;
  top: 0;
  /* min-width: 360px; */
  height: 100%;
  z-index: 1;
  width: 100%;
}
.products-sec .slick-list {
  padding: 0 13% 0 0 !important;
}
.products-sec .slick-slide {
  margin-left: 50px;
}
.product-slider .slick-next:before {
  content: "\f054";
  font-family: FontAwesome;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.product-slider .slick-prev:before {
  content: "\f053";
  font-family: FontAwesome;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.product-slider .slick-arrow::before {
  color: #a8906e;
  font-size: 18px;
}
.product-slider .slick-prev,
.product-slider .slick-next {
  font-size: 0;
  position: relative;
  width: 35px;
  height: 35px;
  border: none;
  background-color: transparent;
}
.product-slider .slick-next.slick-arrow {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  background-color: #ebebeb;
  border-radius: 50%;
  opacity: 0.3;
}
.product-slider .slick-prev.slick-arrow {
  position: absolute;
  top: 50%;
  left: 71px;
  transform: translate(-50%, -50%);
  background-color: #ebebeb;
  border-radius: 50%;
  opacity: 0.3;
  z-index: 1;
}
.product-slider:hover .slick-next,
.product-slider:hover .slick-prev {
  opacity: 1;
}
.product-slider {
  opacity: 0;
}

.product-slider.slick-initialized.slick-slider {
  opacity: 1;
}

.best-product-sec .carousel-control-prev {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  top: auto;
  background-color: #a8906e;
  height: 100px;
  opacity: 1;
}
.best-product-sec .carousel-control-next {
  position: absolute;
  left: 464px;
  bottom: 0;
  width: 60px;
  top: auto;
  background-color: #a8906e;
  height: 100px;
  opacity: 1;
}
.best-product-sec .carousel-control-next:focus,
.best-product-sec .carousel-control-next:hover,
.best-product-sec .carousel-control-prev:focus,
.best-product-sec .carousel-control-prev:hover {
  opacity: 1;
}

/*************** ABOUT SEC  ***************/

.about-sec h5 {
  color: #a8906e;
  font-weight: 800;
  font-size: 38px;
  margin-bottom: 0;
}

.about-sec h4 {
  font-weight: 400;
  font-size: 40px;
  color: #1c191a;
}

.about-sec p {
  color: #333333;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 18px;
}
.about-sec h6 {
  font-size: 20px;
  font-weight: 600;
  color: #737272;
  margin-bottom: 18px;
}
.about-content {
  padding-left: 35px;
}
/*************** BEST PRODUCT SECTION  ***************/
.best-product-sec {
  position: relative;
  padding-top: 70px;
}
.best-product-sec:before {
  content: "";
  background-color: #f6f5f3;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: calc(100% - 78px);
  z-index: -1;
}
.best-product-sec h3 {
  font-weight: 300;
  text-align: center;
  max-width: 620px;
  margin: 0 auto 20px;
}

.best-product-sec h4 {
  max-width: 580px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin: 0 auto 30px;
}
.best-product-sec h3 strong {
  color: #a8906e;
  font-weight: 700;
}
.best-prod-name {
  position: absolute;
  bottom: 0;
  left: 60px;
  z-index: 1;
}
.best-prod-name {
  background-color: #a8906e;
}
.best-product-sec .carousel-item img {
  max-height: 700px !important;
  object-fit: cover;
  min-height: 320px;
}

.best-prod-name a {
  text-decoration: none;
  color: #fff;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 405px;
  font-size: 30px;
  font-family: "Plus Jakarta Sans";
  font-weight: 300;
}
/*************** UTOPIA VERTICAL  ***************/
.ut-cards-wrapper {
  display: flex;
  position: relative;
  margin-top: 90px;
  justify-content: center;
}

.ut-card-info {
  position: absolute;
  z-index: 3;
  color: #fff;
  bottom: 30px;
  width: 100%;
  padding: 0 40px;
}

.ut-card {
  position: relative;
  width: 25%;
}
.ut-card img {
  width: 100%;
  position: relative;
  height: 604px;
  object-fit: cover;
}

.ut-card-img-wrapper {
  transition: 0.3s ease-in-out;
  position: relative;
}

.ut-card-img-wrapper:before {
  background: linear-gradient(0deg, rgba(29, 19, 4, 0.92) 0%, rgba(16, 24, 40, 0.08) 66.21%);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.ut-card:hover .ut-card-img-wrapper {
  transform: scaleY(1.12);
  z-index: 1;
}

.read-more-btn {
  background: #a8906e;
  color: #332d25;
  text-decoration: none;
  width: 150px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  /* margin: 0 auto; */
  height: 44px;
  font-weight: 600;
  font-family: "Plus Jakarta Sans";
  display: none;
  margin: 15px auto 0;
}
.ut-vertical-sec {
  position: relative;
  margin-left: 78px;
  padding-top: 120px;
}
.ut-vertical-sec:before {
  content: "";
  background-color: #f6f5f3;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.ut-vertical-sec h3 {
  text-align: center;
  font-weight: 300;
  color: #1a202c;
}
.ut-vertical-sec h3 strong {
  font-weight: 800;
  color: #a8906e;
}
.ut-vertical-sec h4,
.ut-vertical-sec h5 {
  font-size: 18px;
  font-weight: 400;
  margin: 0 auto;
  max-width: 741px;
  text-align: center;
}
.ut-vertical-sec .ut-card-heading {
  font-size: 30px;
  font-weight: 800;
  max-width: 280px;
  color: #fff;
  text-align: left;
}
.ut-vertical-sec .ut-card-content {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  text-align: left;
}
.ut-card:hover .ut-card-content {
  display: none;
}
.ut-card:hover .read-more-btn {
  display: flex;
}

.ut-card:hover .ut-card-heading {
  text-align: center;
  margin: 0 auto;
  min-width: 222px;
}
.ut-card:hover .ut-card-info {
  margin: 0 auto;
}
/*************** LEADER SECTION  ***************/

.leader-sec h4 {
  text-align: center;
  font-weight: 300;
  max-width: 700px;
  margin: 0 auto 10px;
  color: #1c191a;
}
.leader-sec h4 strong {
  font-weight: 700;
  color: #a8906e;
}
.leader-sec h5 {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  max-width: 656px;
  margin: 0 auto 30px;
}
.leader-cards-wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 1350px;
  margin: 0 auto;
  gap: 10px;
}

.leader-cards-wrapper img {
  width: 100%;
  height: 450px;
  object-fit: cover;
  mix-blend-mode: multiply;
}
.leader-cards-wrapper h3 {
  width: 100%;
  display: flex;
  background: #a8906e;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: white;
  font-weight: 600;
  min-height: 60px;
  margin-bottom: 0;
}
.leader-cards-wrapper h3 a {
  color: #fff;
  text-decoration: none;
  text-align: center;
  width: 100%;
}
.leader-card {
  background-color: #f4f4f4;
}
/*************** SUSTAINABLE SECTION  ***************/
.sustainable-sec {
  padding: 20px 0;
  background-image: linear-gradient(0deg, rgba(28, 30, 24, 0.6) 9.66%, rgba(16, 24, 40, 0.08) 66.21%), url("../images/sustainable-bg.webp");
  background-size: cover;
  background-position: center;
  position: relative;
  margin-bottom: 200px;
}
.webp-not-supported .sustainable-sec {
  background-image: linear-gradient(0deg, rgba(28, 30, 24, 0.6) 9.66%, rgba(16, 24, 40, 0.08) 66.21%), url("../images/sustainable-bg.jpg");
}
.sustainable-sec .container {
  position: relative;
}
.sustainable-sec ul {
  padding: 0 0 0 0;
  list-style: none;
}

.sustainable-sec h4 {
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  margin: 0;
  line-height: 50px;
}

.sustainable-sec p {
  color: #fff;
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 0;
  line-height: 24px;
}

.sustainable-sec li {
  margin-bottom: 35px;
}
.sustainable-content {
  background-color: #7d582480;
  color: #fff;
  max-width: 645px;
  backdrop-filter: blur(10px);
  padding: 74px 36px;
  /* position: absolute;
  bottom: -170px;
  right: 40px; */
  margin-left: auto;
}
.sustainable-content h3 {
  font-size: 46px;
  font-weight: 300;
  margin-bottom: 20px;
}

.sustainable-content h3 strong {
  font-weight: 800;
}
.sustainable-content p {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.3;
}
/*************** PRESS & BLOG SECTION  ***************/
.press-blog-sec {
  position: relative;
}
.press-blog-sec:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  background-color: #f6f5f3;
  z-index: -1;
}
.press-blog-sec h4 {
  font-weight: 300;
  color: #1a202c;
  text-align: center;
}
.press-blog-sec h4 strong {
  font-weight: 800;
  color: #a8906e;
}
.press-blog-sec h5 {
  font-size: 18px;
  max-width: 741px;
  text-align: center;
  color: #1a202c;
  margin: 0 auto;
}

.life-inner-desc h3.text-decoration-underline {
  color: #a8906e;
  font-size: 27px;
  font-weight: 500;
}
.press-blog-wrapper {
  display: flex;
  gap: 25px;
  margin-top: 45px;
  justify-content: space-between;
}
.press-blog-card a {
  max-width: 273px;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 25px;
  display: block;
  text-decoration: none;
  color: #333333;
  margin-bottom: 12px;
}
.press-blog-card h3 {
  font-size: 14px;
  margin-top: 15px;
}
/*************** MAP SECTION  ***************/

.map-wrapper {
  position: relative;
  padding-top: 65px;
  padding-bottom: 35px;
}
.map-sec h4 {
  text-align: center;
  margin-bottom: 0;
  font-weight: 300;
  color: #1a202c;
}

.map-sec h5 {
  font-size: 18px;
  text-align: center;
  color: #333333;
  max-width: 800px;
  margin: 10px auto;
  width: 95%;
}

.map-sec h4 strong {
  font-weight: 800;
  color: #a8906e;
}
.world-map {
  width: 100%;
}
.hotspots {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.point {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  color: #eee6f0;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  border-radius: 100%;
}
.point:hover {
  z-index: 9;
}
.point:hover .tooltip-wrapper {
  max-height: 1000px;
  opacity: 1;
  min-width: 180px;
  padding: 12px;
  overflow: initial;
}

.point-1 {
  top: 45%;
  left: 26%;
}
.point-2 {
  top: 24%;
  left: 19%;
}
.point-3 {
  top: 36%;
  left: 48%;
}
.point-4 {
  top: 51.5%;
  left: 66%;
}
.point-5 {
  top: 41.5%;
  left: 48.5%;
}
.dot {
  position: absolute;
}

.dot {
  position: absolute;
}

.tooltip-wrapper {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 0;
  min-width: 180px;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 55px;
  z-index: 1;
  border-radius: 8px;
  box-shadow: 6px 4px 14px 10px #10182814;
  opacity: 0;
  max-height: 0;
  transition: 0.5s ease-in-out;
  overflow: hidden;
}

.tooltip-wrapper img {
  width: 20px;
  height: 20px;
}

.tooltip-wrapper h2 {
  font-size: 18px;
  margin-top: 9px;
  color: #23457b;
  font-weight: 500;
}
.tooltip-wrapper h3 {
  font-size: 15px;
  color: #6f6f6f;
}

.tooltip-wrapper::after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 9.5px 0 9.5px;
  border-color: #ffffff transparent transparent transparent;
  bottom: -12px;
  position: absolute;
}
.contact-item {
  width: 33%;
  display: flex;
  text-align: center;
  flex-direction: column;
}
.contact-item h4 {
  color: #76511c;
  font-weight: 600;
  font-size: 24px;
}

.contact-list {
  display: flex;
  justify-content: space-between;
}

.contact-item p {
  color: #18181b;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 15px;
}

.contact-item a {
  color: #000000;
  text-decoration: none;
  font-weight: 600;
  width: fit-content;
  margin: 0 auto;
}

/*************** FOOTER  ***************/
footer * {
  font-family: "Plus Jakarta Sans";
}
.footer-top {
  background-color: #333333;
  padding: 20px 0;
  min-height: 220px;
  display: flex;
}

.footer-top .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-top .container a {
  width: 215px;
  height: 50px;
  background-color: #fff;
  text-decoration: none;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #77654b;
  font-weight: 700;
  line-height: 1;
}
.footer-top .container a:hover {
  background-color: #77654b;
  color: white;
}
.footer-top h3 {
  font-size: 30px;
  max-width: 600px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 1.7;
}
.main-footer {
  background-color: #77654b;
  color: #fff;
}
.main-footer .container:before {
  content: "";
  background-color: #e4e4e7;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  z-index: 1;
}
.main-footer .container {
  position: relative;
  padding-top: 55px;
  padding-bottom: 55px;
}

.link-list {
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 30px;
}

.link-list a {
  text-decoration: none;
  color: #fff;
  font-size: 14px;
}
footer p {
  font-weight: 400;
  font-size: 14px;
  max-width: 400px;
  font-family: "Plus Jakarta Sans";
}

.footer-heading {
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 16px;
}
.social-links-wrapper {
  display: flex;
  gap: 30px;
  margin-top: 30px;
}
.social-links-wrapper a {
  color: #fff;
}
.link-list a:hover,
.social-links-wrapper a:hover {
  opacity: 0.85;
}

/********************* ABOUT US PAGE START  *********************/
.about-banner {
  background-image: url("../images/about-banner.webp");
  min-height: 460px;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.webp-not-supported .about-banner {
  background-image: url("../images/about-banner.jpg");
}
.about-banner:before {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.75) 0, rgba(16, 24, 40, 0.08) 93.21%);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.about-banner h1 {
  color: #fff;
  font-size: 46px;
  font-weight: 300;
  text-transform: uppercase;
  max-width: 500px;
  line-height: 1;
}

.about-banner h1 strong {
  font-weight: 800;
}

.about-banner h3 {
  font-weight: 400;
  color: #fff;
  max-width: 530px;
  font-size: 16px;
  margin-top: 12px;
  line-height: 1.5;
}
.about-banner-content {
  padding-left: 40px;
}
/*************** ABOUT US SECTION  ***************/
.about-sec.about-pg {
  background-image: url("../images/about-bg-sec.webp");
}
.webp-not-supported .about-sec.about-pg {
  background-image: url("../images/about-bg-sec.png");
}

/*************** MISSION & VISION SECTION  ***************/

.mission-vision-wrapper {
  display: flex;
  width: calc(100% - 145px);
}

.mission-sec h2 {
  color: #a8906e;
  text-align: center;
  font-weight: 700;
  margin-bottom: 40px;
}
.mission-col,
.vision-col {
  color: #fff;
  padding: 80px 100px;
  width: 50%;
}
.mission-col {
  background-color: #a8906e;
}
.vision-col {
  background-color: #a8906e;
}

.mission-sec h3 {
  font-weight: 800;
  font-size: 94px;
  line-height: 1;
  padding-bottom: 20px;
}
.mission-sec h4 {
  font-size: 26px;
  line-height: 1.2;
  font-weight: 400;
}

/*************** OUR VALUES SECTION  ***************/
.values-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.5px;
}

.val-col {
  width: calc(33.33% - 0.3px);
  height: 414px;
}

.val-col h3 {
  color: #1a202c;
  font-weight: 300;
  font-size: 80px;
  max-width: 270px;
  margin-bottom: 0;
  line-height: 1;
}

.val-col h3 strong {
  font-weight: 800;
  color: #a8906e;
}

.val-col-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f3f3;
}
.val-col-content {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
}
.val-col-2 {
  background-image: url("../images/integrity.webp");
}
.val-col-3 {
  background-image: url("../images/team-work.webp");
}
.val-col-4 {
  background-image: url("../images/inovation.webp");
}
.val-col-5 {
  background-image: url("../images/quality.webp");
}
.val-col-6 {
  background-image: url("../images/improvement.webp");
}
.webp-not-supported .val-col-2 {
  background-image: url("../images/integrity.png");
}
.webp-not-supported .val-col-3 {
  background-image: url("../images/team-work.png");
}
.webp-not-supported .val-col-4 {
  background-image: url("../images/inovation.png");
}
.webp-not-supported .val-col-5 {
  background-image: url("../images/quality.png");
}
.webp-not-supported .val-col-6 {
  background-image: url("../images/improvement.png");
}

.val-col-content h4 {
  font-weight: 700;
  color: #fff;
  font-size: 30px;
  margin-bottom: 10px;
  line-height: 1;
}
.val-col-content h5 {
  color: #fff;
  max-width: 444px;
  font-size: 18px;
  line-height: 1.2;
}
.val-txt {
  margin-top: auto;
  padding-left: 50px;
  padding-right: 50px;
}

.val-col-content h5 {
  height: 100px;
}
.val-col-content:before {
  content: "";
  background: linear-gradient(0deg, rgba(125, 88, 36, 0.67) 0%, rgba(16, 24, 40, 0.08) 66.21%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
}
.val-col-content {
  position: relative;
  z-index: 1;
}
/*************** INFRASTRUCTURE SECTION  ***************/
.department-sec h2 {
  max-width: 575px;
  margin: 0 auto;
  font-weight: 300;
  line-height: 1;
  color: #1c1a1b;
  margin-bottom: 15px;
}
.department-sec h2 strong {
  font-weight: 700;
  color: #a8906e;
}
.department-sec h3 {
  color: #333333;
  font-size: 26px;
  text-align: center;
  max-width: 950px;
  margin: 0 auto 70px;
}
.department-sec #v-pills-tab {
  gap: 1px;
  min-width: 390px;
  justify-content: space-between;
}

.department-sec .nav-pills .nav-link {
  border-radius: 0;
  background-color: #a8906e;
  height: 80px;
  text-align: left;
  color: #77654b;
  font-weight: 700;
  font-size: 24px;
  padding-left: 30px;
}
.department-sec .nav-pills .nav-link.active {
  background-color: #a8906e;
  color: #fff;
}
.department-sec .nav-pills .nav-link:hover {
  background-color: #a8906e;
  color: #fff;
}

.infra-tabs {
  max-width: calc(100% - 145px);
  margin-left: auto;
}
.department-sec .tab-content > .active:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #76511c 0%, rgba(73, 43, 0, 0.12) 100%);
}
.department-sec .tab-pane {
  position: relative;
  background-image: url("../images/rnd.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.webp-not-supported .department-sec .tab-pane {
  background-image: url("../images/rnd.jpg");
}

.tab-inner-content {
  position: relative;
  z-index: 2;
  margin-top: auto;
  padding: 0 50px 50px;
}
.tab-inner-content h4 {
  font-size: 32px;
  font-weight: 800;
  color: #fff;
  font-family: "Plus Jakarta Sans";
  max-width: 290px;
}
.tab-content > .active {
  display: flex;
}

.tab-inner-content p {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  font-family: "Plus Jakarta Sans";
  color: #fff;
}
.infra-btn {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #a8906e;
  text-decoration: none;
  color: #000;
  border-radius: 4px;
  height: 44px;
  font-weight: 800;
  border: 1px solid #a8906e;
  font-weight: 600;
  margin-top: 30px;
}
.ceo-message-img-wrapper {
  width: fit-content;
  background: #fffaf1;
  border: 1px solid #a88b68;
}
.ceo-message-img-wrapper img {
  width: 100%;
}
/********************* ABOUT US PAGE END  *********************/
/********************* INFRASTRUCTURE PAGE START  *********************/
.about-banner.infra-pg {
  background-image: url("../images/our-division-banner.webp");
}
.webp-not-supported .about-banner.infra-pg {
  background-image: url("../images/our-division-banner.jpg");
}
.about-banner.infra-pg h1 {
  max-width: 645px;
}
.about-sec.infra-pg h4 span {
  color: #a8906e;
  font-weight: 700;
}
.infra-left-sec,
.infra-right-sec {
  position: relative;
  margin-bottom: 280px;
}
.infra-left-sec {
  margin-left: 40px;
}
.infra-right-sec {
  margin-right: 40px;
}
.infra-content-wrapper {
  /* background: #7d5824ab; */
  /* background-image: url("../images/bg.svg");
  background-size: cover;
  background-repeat: no-repeat; */
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 1px 0.5px rgba(180, 180, 180, 0.8);
  padding: 95px 70px;
  max-width: 670px;
  color: #fff;
  position: absolute;
  top: 50px;
  left: 50px;
  height: calc(100% + 80px);
  backdrop-filter: blur(8px);
}

.infra-content-wrapper h3 {
  font-weight: 800;
  color: #1c1a1b;
  font-size: 47px;
  margin-bottom: 15px;
  line-height: 1.2;
  text-transform: uppercase;
}

.infra-content-wrapper p {
  font-size: 22px;
  line-height: 1.3;
  font-weight: 400;
  margin-bottom: 0;
  color: #1a202c;
}
.infra-content-wrapper ul {
  margin: 15px 0;
  padding-left: 10px;
}
.infra-content-wrapper ul li {
  margin-bottom: 15px;
  color: #1a202c;
}
.infra-right-sec .infra-content-wrapper {
  left: auto;
  right: 50px;
}
.infra-left-sec img,
.infra-right-sec img {
  max-height: 845px;
  object-fit: cover;
}
/* profile-section */
.profile-card img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  object-fit: cover;
  /* background-color: #d9d9d9; */
  background-color: #f9f9f9;
}

.profile-card {
  position: relative;
  height: 285px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(168 144 110/50%);
}
.user-info {
  z-index: 2;
  position: relative;
  padding: 8px 5px;
}

/********************* INFRASTRUCTURE PAGE END  *********************/
/********************* PRODUCTS PAGE START  *********************/
.about-banner.products-pg {
  background-image: url("../images/product-banner.webp");
}
.webp-not-supported .about-banner.products-pg {
  background-image: url("../images/product-banner.png");
}

.about-banner.products-pg h1 {
  max-width: 645px;
}
/*********************  PRODUCT ITEM DESC SECTIONS *********************/
.product-item-desc-sec {
  display: flex;
  align-items: flex-end;
  position: relative;
}
.product-left-content:before {
  content: "";
  width: 50px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #a8906e;
}
.product-content-wrapper {
  width: 50%;
  padding-left: 120px;
  padding-right: 50px;
  padding-bottom: 10px;
}

.product-img {
  width: 50%;
}

.product-item-desc-sec h2 {
  color: #1c1a1b;
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 25px;
  font-family: "Plus Jakarta Sans";
}

.product-item-desc-sec h3 {
  color: #1a202c;
  font-size: 30px;
  font-weight: 400;
  line-height: 1.2;
}

.product-item-desc-sec h4 {
  color: #666666;
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
  line-height: 28px;
}
.product-right-content .product-content-wrapper {
  padding-right: 120px;
  padding-left: 50px;
}

.product-right-content:before {
  content: "";
  width: 50px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #a8906e;
}

.profile-card h3 {
  color: #fff;
  z-index: 1;
  font-weight: 700;
  font-family: "Plus Jakarta Sans";
  text-align: center;
  margin-top: auto;
  font-size: 16px;
  margin-top: auto;
  z-index: 2;
  position: relative;
  text-transform: uppercase;
}

.profile-card p {
  max-height: 0;
  overflow: hidden;
  color: white;
  transition: 0.3s ease out;
  z-index: 1;
  margin: 0 auto;
  max-width: 95%;
}

.profile-card h4 {
  color: #fff;
  z-index: 1;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.profile-card:hover p {
  max-height: 1000px;
  text-align: center;
  line-height: 1.5;
  font-size: 13px;
}
.profile-card:hover h4,
.profile-card:hover h3 {
  display: none;
}
.profile-card:hover .user-info {
  max-height: 400px;
}
.profile-card .user-info {
  background-color: rgb(168 144 110 / 80%);
  backdrop-filter: blur(2px);
  transition: 0.5s ease-out;
  max-height: 73px;
  margin-top: auto;
  height: 100%;
}
/********************* PRODUCTS PAGE END  *********************/
/********************* LEADERSHIP PAGE START  *********************/
.about-banner.leadership-pg {
  background-image: url("../images/leadership-banner-bg.webp");
  background-position: center;
}
.webp-not-supported .about-banner.leadership-pg {
  background-image: url("../images/leadership-banner-bg.jpg");
}
.about-banner.leadership-pg h1 {
  max-width: 620px;
}
.leader-desc-sec h2 {
  text-align: center;
  font-weight: 300;
  color: #1c1a1b;
  max-width: 510px;
  margin: 0 auto 10px;
  line-height: 1;
}

.leader-desc-sec h2 strong {
  color: #a8906e;
  font-weight: 700;
}

.leader-desc-sec h4 {
  font-size: 24px;
  text-align: center;
  font-weight: 300;
  max-width: 960px;
  margin: 0 auto;
  line-height: 1.15;
  color: #333333;
}
.leader-profile-sec .container {
  display: flex;
}

.leader-profile-sec h2 {
  color: #a8906e;
  font-weight: 700;
  margin-bottom: 0;
  font-size: 48px;
  line-height: 1.1;
}

.leader-profile-sec span {
  color: #000;
  font-weight: 400;
  font-size: 20px;
  line-height: 1;
  margin-bottom: 22px;
  display: block;
}

.leader-profile-sec h3 {
  font-size: 30px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 25px;
}

.leader-profile-sec h4 {
  color: #000;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 14px;
}
.leader-info {
  padding-left: 50px;
  margin-top: 10px;
}
.right-side-profile .leader-info {
  padding-left: 0;
  padding-right: 50px;
}
.leader-profile-sec {
  margin-bottom: 75px;
}
section.leader-profile-sec img {
  height: 500px;
  background: #fffaf1;
  border: 1px solid #a88b68;
}
/********************* LEADERSHIP PAGE END  *********************/

/*************** TOUR PAGE START  ***************/

.tourForm label {
  display: block;
}

form select,
form input {
  width: 100%;
  padding-left: 12px;
  font-size: 16px;
  color: #1a202c;
  border: 2px;
}
form select {
  position: relative;
  -webkit-appearance: none;
  cursor: pointer;
  height: 50px;
}
/* form textarea {
  width: 100%;
  height: 160px;
  border: 0.5px solid #c1c1c1;
  border-radius: 5px;
  margin-bottom: 20px;
  background: #fff;
  padding: 3px 15px;
  position: relative;
  display: block;
  resize: none;
} */

form select,
form input,
form textarea {
  font-size: 16px;
}
form label {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
}
/* .tourForm label.error {
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #ff5c4c;
  display: block;
  margin-top: -12px;
  margin-bottom: 20px;
} */
/* .tourForm {
  max-width: 500px;
  margin: 0 auto;
} */
/* .tourForm button {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  background-color: #fff;
  color: #a8906e;
  border: none;
  border-radius: 5px;
  width: 200px;
  height: 40px;
  transition: 0.3s ease-in-out;
  border: 0.5px solid #a8906e;
} */

/* .tourForm button:hover {
  color: #a8906e;
  background-color: white;
} */
input.error,
textarea.error,
select.error {
  border: 1px solid #ff5c4c;
}

.tour-sec {
  background-color: #f6f5f3;
}
.input-groups i {
  right: 17px;
  top: 17px;
  color: #212529;
  pointer-events: none;
  position: absolute;
}
label.error:before {
  font-family: FontAwesome;
  content: "\f06a\00a0";
  margin-right: 4px;
  font-size: 14px;
}
.required-field:after {
  position: relative;
  content: "*";
  margin-left: 2px;
  color: #ff5c4c;
}
.input-groups {
  position: relative;
}
.tour-sec h3 strong {
  font-weight: 700;
  color: #a8906e;
}
/* form.tourForm {
  background: #956d36ab;
  padding: 60px 50px;
  max-width: 650px;
  color: #fff;
  position: absolute;
  top: 50px;
  left: 50%;
  height: calc(100% + 80px);
  backdrop-filter: blur(8px);
  width: 100%;
  transform: translateX(-50%);
} */

.tour-form-wrapper {
  position: relative;
}
.tour-form-bg {
  filter: brightness(0.5);
}
.xdsoft_datetimepicker .xdsoft_calendar td:hover,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
  background-color: #a8906e !important;
}
/*************** TOUR PAGE END   ***************/
/*************** CSR PAGE START   ***************/
.csr-pg {
  background-image: url("../images/csr-banner.webp");
}
.webp-not-supported .csr-pg {
  background-image: url("../images/csr-banner.jpg");
}

.csr-desc h2 {
  max-width: 700px;
}
.csr-sec.product-item-desc-sec h2 {
  color: #a8906e;
}
.csr-sec .product-img {
  filter: brightness(0.8);
  transition: 0.5s ease-in-out;
}
.csr-sec .product-img:hover {
  filter: brightness(1);
}
.csr-sec h4 {
  font-size: 25px;
  font-weight: 500;
  line-height: 1.2;
}
/*************** CSR PAGE END   ***************/
/*************** CONTACT PAGE START   ***************/
.contact-pg {
  background-image: url("../images/contact-banner.webp");
}
.webp-not-supported .contact-pg {
  background-image: url("../images/contact-banner.jpg");
}
.contact-desc h4 {
  max-width: 800px;
  line-height: 1.2;
}
/*************** CONTACT PAGE END   ***************/
/*************** PRODUCTION PAGE START   ***************/
.production-pg h1 {
  max-width: 560px;
}
/*************** PRODUCTION PAGE  END   ***************/
/*************** CONTACT PAGE START   ***************/
.form-wrapper {
  display: flex;
  margin-top: 70px;
}
.form-container input,
.form-container textarea {
  border: 1px solid #77654b;
  width: 100%;
  padding-left: 12px;
  font-size: 16px;
  color: #1a202c;
  border: 2px;
}
.form-container input.error,
.form-container textarea.error {
  border: 1px solid #ff5c4c;
}

.form-container {
  width: 50%;
  background-color: #d9d9d9;
  padding: 50px;
}

.form-container label {
  font-size: 16px;
  font-weight: 400;
  color: #352c2b;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.form-wrapper input {
  height: 50px;
}

.form-row {
  margin-bottom: 15px;
}

.form-wrapper .location-list ul {
  list-style: none;
}

.form-wrapper .location-list ul li {
  position: relative;
  padding-left: 40px;
  margin-bottom: 50px;
}

.form-wrapper .location-list li i {
  position: absolute;
  top: 6px;
  left: 0;
  font-size: 24px;
}

.form-wrapper h3 {
  font-size: 22px;
  font-weight: 600;
  color: #1a202c;
  margin-bottom: 2px;
}

.form-wrapper p {
  color: #4f5b67;
  font-size: 16px;
  font-weight: 400;
}
.location-list {
  width: 50%;
  padding-top: 90px;
  padding-left: 60px;
}
.form-submit-btn {
  background-color: #352c2b;
  border: none;
  color: white;
  width: 250px;
  margin-top: 30px;
  height: 60px;
  font-size: 18px;
  font-weight: 700;
  border-radius: 2px;
  text-transform: uppercase;
}
.form-wrapper textarea {
  resize: none;
  height: 150px;
  padding: 6px 12px;
  display: block;
  border-radius: 0;
}
.form-wrapper p a {
  text-decoration: none;
  color: inherit;
  font-size: inherit;
}
.form-wrapper label.error {
  color: #ff5c4c;
  font-size: 14px;
  padding-top: 10px;
  margin-bottom: -15px;
  top: -10px;
  position: relative;
}
.form-wrapper .input-groups label.error {
  top: -8px;
}
/*************** CONTACT PAGE END   ***************/
.joinus-pg {
  background-image: url("../images/joinus2.webp");
}
.tour-us-pg {
  background-image: url("../images/tour-banner.webp");
}
.webp-not-supported .joinus-pg {
  background-image: url("../images/joinus2.jpg");
}

.webp-not-supported .tour-us-pg {
  background-image: url("../images/tour-banner.jpg");
}
label#file-error {
  position: absolute;
  top: 38px;
  left: 0;
}
/*************** FILE UPLOAD START  ***************/

.file-upload {
  display: block;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}
label span {
  font-size: 12px;
  text-transform: none;
}
.file-upload .file-select {
  display: block;
  /* border: 2px solid #dce4ec; */
  color: #34495e;
  cursor: pointer;
  height: 50px;
  line-height: 50px;
  text-align: left;
  background: #ffffff;
  cursor: pointer;
  position: relative;
}
.file-upload .file-select .file-select-button {
  background: #dce4ec;
  padding: 0 10px;
  display: inline-block;
  height: 48px;
  line-height: 50px;
}
.file-upload .file-select .file-select-name {
  padding: 0 10px;
  margin-left: 0;
  position: absolute;
  top: 0;
  left: 67px;
  height: 50px;
  display: inline-block;
  width: calc(100% - 80px);
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
input#file {
  padding-left: 0;
}
/* .file-upload .file-select.error {
  border: 1px solid #ff5c4c !important;
} */
.file-upload .file-select:hover {
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.file-upload .file-select:hover .file-select-button {
  background: #34495e;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.file-upload.active .file-select {
  border-color: #3fa46a;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  position: relative;
}
.file-upload.active .file-select .file-select-button {
  background: #3fa46a;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.file-upload .file-select input[type="file"] {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
.file-upload .file-select.file-select-disabled {
  opacity: 0.65;
}
.file-upload .file-select.file-select-disabled:hover {
  cursor: default;
  display: block;
  border: 2px solid #dce4ec;
  color: #34495e;
  cursor: pointer;
  height: 50px;
  line-height: 50px;
  margin-top: 5px;
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  position: relative;
}
.file-upload .file-select.file-select-disabled:hover .file-select-button {
  background: #dce4ec;
  color: #666666;
  padding: 0 10px;
  display: inline-block;
  height: 50px;
  line-height: 50px;
}
.file-upload .file-select.file-select-disabled:hover .file-select-name {
  line-height: 50px;
  display: inline-block;
  padding: 0 10px;
}
/*************** FILE UPLOAD END  ***************/

/*************** MEDIA AND PRESS START  ***************/
.publish-date {
  color: #333333;
  font-size: 12px;
  margin-top: 5px;
  /* border-bottom: 1px solid #cbcbcb; */
  padding-bottom: 5px;
}

.blog-heading {
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #cbcbcb;
  height: 45px;
  color: #1a202c;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blog-excerpt {
  color: #666666;
  font-weight: 400;
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .blog-list-card a {
  color: #a8906e;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
}

.blog-list-card a:hover {
  text-decoration: underline;
} 
*/
.blog-list-card a.read-more-press-btn {
  font-size: 14px;
  font-weight: 600;
  background-color: #a8906e;
  color: white;
  padding: 8px 15px;
  border-radius: 2px;
  margin-top: 3px;
  text-decoration: none;
  display: inline-block;
  border: 1px solid #a8906e;
  transition: 0.3s ease-in-out;
}

.blog-list-card a.read-more-press-btn:hover {
  background-color: #fff;
  color: #a8906e;
}

.prev-blog-img {
  height: 365px;
  overflow: hidden;
}
.prev-blog-img img {
  width: 100%;
  object-fit: cover;
  height: 365px;
}
.prev-blog-img img:hover {
  opacity: 0.9;
}
.blog-row {
  row-gap: 25px;
}
/*************** MEDIA AND PRESS END  ***************/
.two-col {
  display: flex;
  gap: 35px;
}

.two-col .link-list {
  margin-top: 0;
}
.profile-card.withoutFilter img {
  filter: none;
}
/*************** PRESS DETAIL START  ***************/
.press-detail-sec h1 {
  font-weight: 300;
  font-size: 48px;
}
.press-detail-sec h1 strong {
  color: #a8906e;
  font-weight: 700;
}
.press-detail-sec p {
  font-size: 18px;
  line-height: 1.4;
}
.press-detail-sec iframe {
  width: 100%;
  height: 484px;
  margin: 50px 0;
}
.press-detail-sec a {
  color: #a8906e;
}
/*************** PRESS DETAIL END  ***************/
.csr-sec .product-content-wrapper h4 {
  font-size: 20px;
  line-height: 1.3;
}
.production-pg {
  background-image: url("../images/production-bg.webp");
}
.webp-not-supported .production-pg {
  background-image: url("../images/production-bg.png");
}
a.download-btn {
  height: 45px;
  background-color: #77654b;
  text-decoration: none;
  border-radius: 3px;
  justify-content: center;
  font-size: 14px;
  color: #fff;
  font-weight: 700;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 0 20px;
  margin-top: 25px;
  text-transform: capitalize;
  border: 1px solid #77654b;
}

a.download-btn:hover {
  color: #77654b;
  background-color: #fff;
}
/* ----------------------------------
COOKIES SECTION -------------------*/
#cn {
  position: fixed;
  bottom: 25px;
  overflow: hidden;
  border-radius: 10px;
  z-index: 9999;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 860px;
  width: 100%;
  transition: 0.5s;
  background-color: #cbcbcb;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  border: 1px solid #77654b;
}

.cookie-content {
  z-index: 9999;
  position: relative;
  padding: 20px;
}

.cookie-content h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #77654b;
}

.cookie-content h4 i {
  margin-right: 5px;
}

.inner-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.cookie-content p {
  width: 100%;
  margin: 0;
  margin-right: 50px;
  font-size: 14px;
}

.cookie-content p a {
  color: #fff;
}

.cookie-content p a:hover {
  color: #77654b;
}

button.btn.cookie-btn {
  color: var(--cc-white);
  background-color: #fff;
  font-size: 15px;
  padding: 6px 20px;
  font-weight: 600;
  border-radius: 4px;
}

button.btn.cookie-btn:hover {
  background-color: #77654b;
  border: 1px solid #77654b;
  color: #fff;
}

#cn.hidden {
  opacity: 0 !important;
  visibility: hidden;
  transform: translate(-50%, 100%);
  transition: 0.5s;
}

.img-resize .portal-img,
.img-resize .portal-circle-img {
  object-fit: contain;
}

.cookie-box h4 {
  font-weight: 600;
  font-size: 16px;
}

.cookie-box .blog-detail-para {
  margin-bottom: 15px;
}

p.blog-detail-para.indent {
  padding-left: 18px;
}

.cookie-box a {
  font-weight: 500;
  line-height: 15px;
  color: #ff5c4c;
  margin: 0px;
  word-wrap: break-word;
}

.cookie-box a:hover {
  color: #77654b;
}
/* .contactForm {
  visibility: hidden;
  opacity: 0;
} */

.submission-msg {
  position: absolute;

  top: 51px;
  left: 45px;
  width: calc(100% - 90px);
}
/*************** CAREER PAGE START  ***************/
.career-bg {
  background-image: url("../images/career-bg.webp");
}
.webp-not-supported .career-bg {
  background-image: url("../images/career-bg.jpg");
}
.career-desc h4 strong {
  font-weight: 700;
}
.recruitment-process-sec {
  background-color: #efefef;
}
.recruitment-process-sec h2 {
  max-width: 1000%;
}
.benefits-cards-row {
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
  row-gap: 40px;
}

.benefit-card {
  width: 20%;
  text-align: center;
}

.benefit-card h5 {
  text-align: center;
  color: #1a202c;
  font-size: 18px;
  margin-top: 10px;
  font-weight: 600;
}
.benefits-sec.recruitment-process-sec {
  background-color: transparent;
}

.life-utopia-card {
  position: relative;
}
.ut-life-img-wrapper {
  transition: 0.3s ease-in-out;
}
.life-utopia-card:hover .ut-life-img-wrapper {
  transform: scaleY(1.08);
}

.life-info {
  position: absolute;
  bottom: 0;
  z-index: 1;
  color: white;
}

.ut-life-img-wrapper:before {
  content: "";
  position: absolute;
  background: linear-gradient(0deg, #5c5c5c 4.51%, rgba(0, 0, 0, 0) 61.85%);
  top: 0;
  height: 100%;
  width: 100%;
}

.life-utopia-cards-row {
  display: flex;
  gap: 1px;
  margin-top: 50px;
}

.life-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.life-utopia-cards-row h3 {
  font-size: 26px;
  text-align: center;
  max-width: 210px;
  margin: 0 auto;
  font-weight: 700;
  text-transform: uppercase;
}

.life-info-btn {
  font-size: 16px;
  font-weight: 700;
  border: 1px solid #fff;
  color: #fff;
  text-decoration: none;
  max-width: 185px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 45px;
  margin-bottom: 45px;
}
.life-info-btn:hover {
  background-color: #a8906e;
  /* border-color: #a8906e; */
  color: white;
}

.life-utopia-card img {
  width: 100%;
}
.career-form-sec .form-container input,
.career-form-sec .form-container textarea,
.career-form-sec .form-container select {
  border: 1px solid #77654b;
}
.career-form-sec .form-container input {
  height: 50px;
}
.career-form-sec .form-container {
  width: 100%;
  /* background-color: #efefef; */
  background-color: transparent;
}
/* .career-form-sec .form-container input.error,
.career-form-sec .form-container textarea.error,
.career-form-sec input.error,
.career-form-sec select.error,
.career-form-sec textarea.error {
  border: 1px solid #ff5c4c;
} */
.career-form-sec .form-container label.error {
  color: #ff5c4c;
}
.career-form-sec .file-upload .file-select {
  border: 1px solid #77654b;
}
.career-form-sec .form-submit-btn {
  margin: 35px auto 0;
  display: block;
}
.hidden-field {
  max-width: 0;
  opacity: 0;
  visibility: hidden;
}
/*************** CAREER PAGE JOB OPENING ACCORDION START ***************/
.accordion-item {
  margin-bottom: 25px;
}
.job-accordion-btn {
  width: 100%;
  display: flex;
  border: 0;
  flex-direction: column;
  background-color: transparent;
  position: relative;
}

.co-info {
  display: flex;
  gap: 15px;
}

.co-info h5 {
  color: #1a202c;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}

.accordion-item.current-opening-card {
  border: 1px solid #a8906e;
  outline: none;
  padding: 30px;
  border-radius: 1px;
}
.co-position {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 0;
  color: #1a202c;
  padding-right: 45px;
  text-align: left;
}
.job-accordion-header {
  margin-bottom: 0;
}

.job-accordion-btn::after {
  content: "\f107";
  font-weight: 600;
  font-family: "Font Awesome 6 Free";
  color: #a8906e;
  border: 1px solid #a8906e;
  border-radius: 50%;
  font-size: 20px;
  padding-top: 4px;
  padding-left: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  position: absolute;
  right: 0;
  top: 50%;
  height: 40px;
  transition: 0.2s ease-in-out;
  transform: translateY(-50%);
}
.job-accordion-btn.collapsed::after {
  transform: translateY(-50%) rotate(-90deg);
  color: #1a202c;
  border-color: #1a202c;
}
.accordion-body {
  padding: 0;
}

.job-accordion-btn {
  padding-left: 0;
}
.job-desc {
  color: #1a202c;
}
.apply-btn {
  background-color: #352c2b;
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 48px;
  border-radius: 2px;
  margin-top: 40px;
}
.apply-btn:hover {
  color: white;
  background-color: #a8906e;
}
.accordion-item .accordion-body {
  border-top: 1px dashed transparent;
  margin-top: 15px;
  padding-top: 15px;
}
.accordion-item .show .accordion-body {
  border-top: 1px dashed #ccc;
}
/*************** CAREER PAGE JOB OPENING ACCORDION END ***************/
/*************** CAREER PAGE END ***************/
/*************** LIFE INNER PAGES START ***************/

#mainGallerySlider img {
  height: 600px;
  object-fit: cover;
  object-position: center;
}
#previewGallerySlider .slick-track .slick-slide img {
  width: 125px !important;
  /* height: 90px !important; */
  object-fit: cover;
  max-height: 85px !important;
  min-height: 85px;
  margin: 0 auto;
  cursor: pointer;
}
/* #previewGallerySlider .slick-track {
  width: 730px !important;
  margin: 15px auto;
} */

#previewGallerySlider .slick-arrow,
.single-slider .slick-arrow {
  color: #fff;
  background-color: #a8906e;
  border: none;
  font-size: 19px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 1;
}

#previewGallerySlider .slick-arrow i,
.single-slider .slick-arrow i {
  position: relative;
  top: 2px;
}
.slick-next.slick-arrow {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.slick-prev.slick-arrow {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

#previewGallerySlider {
  margin-top: 35px;
}

/*  .slick-slide {
  width: fit-content;
} */
#previewGallerySlider .slick-slide.slick-current.slick-active img {
  border: 2px solid #a8906e;
}

/* #previewGallerySlider .slick-slide {
  width: fit-content !important;
} */
/*************** LIFE INNER PAGES END ***************/
.benefit-card img:hover {
  opacity: 0.8;
}
/*************** CSR - TCF PAGE START  ***************/
.tcf-main li {
  color: #666;
  font-size: 20px;
  line-height: 1.5;
}
/*************** CSR - TCF PAGES END ***************/

.co-info {
  display: flex;
  gap: 15px;
  flex-direction: column;
  text-align: left;
  margin-top: 8px;
}
/*************** CAREER PAGE JOB OPENING ACCORDION END ***************/
a.view-jobs-btn {
  height: 45px;
  background-color: #77654b;
  text-decoration: none;
  border-radius: 1px;
  font-size: 17px;
  color: #fff;
  font-weight: 700;
  line-height: 1;
  width: fit-content;
  padding: 0 30px;
  margin-top: 25px;
  border: 1px solid #77654b;
  display: flex;
  align-items: center;
  justify-content: center;
}

a.view-jobs-btn:hover {
  color: #fff;
  background-color: transparent;
}
.co-type {
  background-color: #a8906e;
  border-radius: 4px;
  font-size: 17px;
  padding: 4px 10px;
  color: white;
}
.req-pos-type {
  background-color: #a8906e;
  width: fit-content;
  color: #fff;
  padding: 3px 8px;
  border-radius: 3px;
}

.job {
  margin-left: auto;
}

.job-item {
  display: flex;
}

.req-detail-link {
  color: #a8906e;
  font-size: 18px;
  margin-top: 7px;
  display: flex;
  text-decoration: none;
  align-items: center;
  gap: 6px;
  font-weight: 600;
  justify-content: end;
}

.req-detail-link i {
  position: relative;
  top: -1px;
}

.job-item {
  box-shadow: 0 0 3px 1px #a8906e82;
  border-radius: 3px;
  padding: 12px;
  margin-bottom: 30px;
}
.req-title {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 0;
  text-decoration: none;
  color: #333;
}
.req-title:hover {
  color: #a8906e;
}
.job-listing-info-wrapper {
  display: flex;
  /* gap: 13px; */
  font-size: 15px;
  margin-top: 12px;
  color: #6b7280;
  margin-bottom: 0;
  flex-direction: column;
}

.job-listing-info-wrapper i {
  margin-right: 4px;
}
.req-detail-link:hover {
  color: #352c2b;
}

/*************** CAREER PAGE END ***************/
/*************** CAREER JOB DETAIL START ***************/
.jobTitle {
  font-size: 40px;
  font-weight: 700;
}

.job-detail-wrapper {
  color: #666;
}

.job-info-title {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 15px;
  color: #000;
}

.job-info-value {
  font-size: 20px;
  font-weight: 400;
}
.job-detail-wrapper li {
  margin-bottom: 8px;
}
/*************** CAREER JOB DETAIL END ***************/
/*************** CAREER JOB IAMGE PREVIEW START ***************/
.avatar-upload {
  position: relative;
  width: fit-content;
}
.avatar-upload .avatar-edit {
  position: relative;
  right: 12px;
  z-index: 1;
  top: 10px;
}
.avatar-upload .avatar-edit input {
  display: none;
}
.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
  position: absolute;
  right: 0;
}
.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}
.avatar-upload .avatar-edit input + label:after {
  content: "\f093";
  font-family: "FontAwesome";
  color: #757575;
  position: absolute;
  top: 6px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
  font-size: 25px;
}
.avatar-upload .avatar-preview {
  width: 300px;
  height: 300px;
  position: relative;
  /* border-radius: 100%; */
  border: 6px solid #f8f8f8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  /* border-radius: 100%; */
  background-repeat: no-repeat;
  background-position-y: 23%;
  max-width: 200px;
  margin: 0 auto;

  background-position: center;
}
.avatar-upload .avatar-preview .img-added {
  background-size: contain;
  max-width: none;
}
/* #imagePreview:before {
  content: "\f007";
  font-family: "FontAwesome";
  font-weight: 800;
  font-size: 196px;
  left: 61px;
  position: relative;
  color: #b7b6b6;
} */
div#imagePreview.default-img {
  width: 120px;
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.photo-upload-info-txt {
  position: absolute;
  bottom: 2px;
  left: 37px;
  font-weight: 700;
  color: #9b9b9b;
}
.img-max-size-error,
.file-max-size-error {
  color: #ff5c4c;
  margin-top: 5px;
}
label#photoBlob-error {
  margin-top: 5px;
}

.loading {
  pointer-events: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.form-submit-btn.loading {
  background-color: #787878;
}
.career-detail-thankyou-msg.submission-msg {
  position: relative;
  left: 0;
  /* border-top: 6px dotted #77654b; */
  padding-top: 60px;
}
/*************** CAREER JOB IAMGE PREVIEW END ***************/

.multiple-select-wrapper i.fa-angle-down {
  position: absolute;
  right: 17px;
  top: 13px;
}

.remove-work-exp,
.remove-education {
  color: #ff5c4c;
  background: none;
  padding: 0;
  font-size: 23px;
  border: none;
  margin-left: auto;
}

.work-exp-item,
.education-item {
  border: 1px solid #a8906e;
  padding: 20px;
  margin-top: 20px;
}

.remove-work-exp:hover,
.remove-education:hover {
  color: #e93523;
}

.career-form-sec .form-container input[type="checkbox"] {
  height: fit-content;
  border: 0;
  width: fit-content;
  margin-right: 8px;
  position: relative;
  top: 4px;
  /* accent-color: #a8906e; */
}
.add-more-btn,
.add-more-education-btn {
  background-color: #a8906e !important;
  border: 0;
  margin-top: 25px;
  color: white;
  padding: 12px 15px;
  border-radius: 2px;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.add-more-btn:hover,
.add-more-education-btn:hover {
  background-color: rgb(168 144 110 / 80%);
}
.select2-container--default .select2-selection--multiple {
  border-radius: 0;
  border-color: #77654b !important;
  padding-top: 12px;
  padding-bottom: 12px;
}

.multiple-select-wrapper i.fa-angle-down {
  top: 19px;
}
.work-exp-sec {
  margin-bottom: 15px;
}
span.select2-search.select2-search--inline {
  line-height: 14px;
}
/*************** PAGINATION START  ***************/

nav#pagination {
  margin-top: 30px;
  display: flex;
  justify-content: end;
}
nav#pagination ul {
  display: flex;
  gap: 5px;
}

nav#pagination ul li a {
  border: 1px solid #a99a7f;
  min-width: 30px;
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: center;
  color: #333;
}

nav#pagination li {
  list-style: none;
}

nav#pagination li.active {
  background-color: #a99a7f;
}

nav#pagination li.active a {
  color: white;
}

nav#pagination li a {
  text-decoration: none;
}

nav#pagination li.active a {
  color: white !important;
}
/*************** PAGINATION END  ***************/

input[disabled] {
  background-color: #ccc;
  opacity: 0.4;
}
.min-w-330-resp {
  min-width: 330px;
}
.form-sub-heading {
  margin-top: 35px;
  font-weight: 500 !important;
}
.loader {
  border: 10px solid #f3f3f3;
  border-top-color: #cca466;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1.3s linear infinite;
  margin: 0 auto;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#career-loader {
  display: flex;
  position: absolute;
  right: 35px;
  width: 25px;
  height: 25px;
  border-width: 5px;
  bottom: 19px;
}
.apply-now-btn {
  text-decoration: none;
  width: fit-content;
  padding: 4px 12px;
  border-radius: 3px;
  background-color: #a8906e;
  color: #fff;
  font-size: 18px;
}
.apply-now-btn:hover {
  color: #fff;
}
.job-listing-btn {
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-top: 30px !important;
}
/*************** RESPONSIVENESS  ***************/
@media (max-width: 1300px) {
  .profile-card {
    height: 260px;
  }
}
@media (max-width: 1200px) {
  .pt-sec {
    padding-top: 100px;
  }
  .pb-sec {
    padding-bottom: 100px;
  }
  .mt-sec {
    margin-top: 100px;
  }
  .mb-sec {
    margin-bottom: 100px;
  }
  .sec-heading {
    font-size: 50px;
    line-height: 1.1;
  }
  /* HEADER START */
  ul.navbar-nav {
    margin-left: 0;
    gap: unset;
    justify-content: space-between;
    width: 100%;
  }

  /* HEADER END */
  /* PRODUCT SEC START */
  .products-sec h3 {
    font-size: 20px;
    max-width: 700px;
    margin-bottom: 30px;
  }
  /* PRODUCT SEC END */
  /* FOOTER START */
  .link-list a {
    white-space: nowrap;
  }
  /* FOOTER END */
  .about-content {
    padding-left: 0px;
  }

  .about-sec h4 {
    font-size: 22px;
    line-height: 1;
  }
  .about-sec h5 {
    font-size: 32px;
  }
  .about-sec h6 {
    font-size: 18px;
    margin-bottom: 12px;
  }
  .about-sec p {
    font-size: 16px;
  }

  .leader-cards-wrapper img {
    height: 320px;
  }
  .sustainable-sec ul {
    padding-left: 0;
  }
  .sustainable-content {
    right: 0;
  }
  .ut-card-info {
    padding: 0 10%;
  }
  .ut-vertical-sec .ut-card-heading {
    font-size: 26px;
  }
  .ut-vertical-sec .ut-card-content {
    font-size: 16px;
  }
  .ut-card:hover .ut-card-heading {
    min-width: auto;
  }
  .ut-vertical-sec {
    padding-top: 60px;
    margin-left: 45px;
  }
  .mission-sec h3 {
    font-size: 75px;
  }
  .mission-col,
  .vision-col {
    padding: 65px 75px;
  }
  .department-sec h3 {
    font-size: 22px;
    max-width: 85%;
  }
  .product-item-desc-sec h2 {
    font-size: 35px;
    margin-bottom: 10px;
  }

  .product-item-desc-sec h3 {
    font-size: 25px;
  }

  .product-item-desc-sec h4 {
    font-size: 18px;
    margin-top: 0;
  }

  .product-right-content .product-content-wrapper {
    padding-left: 35px;
  }

  .product-content-wrapper {
    padding-left: 80px;
  }
  .infra-content-wrapper {
    padding: 80px 50px;
    max-width: 63%;
  }

  .infra-content-wrapper p {
    font-size: 20px;
    line-height: 1.2;
  }

  .infra-content-wrapper h3 {
    font-size: 45px;
  }
  .leader-profile-sec .container img {
    width: auto;
    object-fit: contain;
    margin-bottom: auto;
  }

  .leader-profile-sec h2 {
    font-size: 42px;
  }

  .leader-profile-sec span {
    font-size: 18px;
  }

  .leader-profile-sec h3 {
    font-size: 26px;
    margin-bottom: 18px;
  }

  .leader-info {
    padding-left: 30px;
  }
  .right-side-profile .leader-info {
    padding-right: 30px;
  }
  .leader-desc-sec h4 {
    font-size: 22px;
    max-width: 95%;
    margin: 0 auto;
  }
  .profile-card {
    height: 215px;
  }
  .benefits-cards-row {
    justify-content: center;
  }
  .benefit-card {
    width: 25%;
  }
  .life-utopia-cards-row {
    flex-wrap: wrap;
    row-gap: 50px;
    justify-content: center;
    justify-content: space-between;
  }

  .life-utopia-card {
    width: 48%;
  }
}
@media (max-width: 991px) {
  .pt-sec {
    padding-top: 80px;
  }
  .pb-sec {
    padding-bottom: 80px;
  }
  .mt-sec {
    margin-top: 80px;
  }
  .mb-sec {
    margin-bottom: 80px;
  }
  .sec-heading {
    font-size: 40px;
  }
  header #navbarSupportedContent {
    position: absolute;
    top: 77px;
    background: #1c191a;
    width: 100%;
    left: 0;
  }

  header nav.navbar .container {
    position: relative;
  }

  header li.nav-item {
    border-bottom: 0.5px solid rgb(255 255 255 / 20%);
    padding-left: 18px;
  }

  header .navbar-nav {
    margin-bottom: 0 !important;
    padding: 20px 25px;
  }

  .nav-item:first-child .nav-link {
    padding-top: 0;
  }
  header .nav-link {
    padding: 5px 0 !important;
  }
  /* HOME BANNER START */
  .banner-content {
    padding-left: 20px;
  }
  .banner-content h1 {
    font-size: 35px;
    max-width: 350px;
  }
  .banner-content h3 {
    max-width: 510px;
    font-size: 15px;
    line-height: 20px;
    margin-top: 12px;
  }
  .banner-btn {
    margin-top: 30px;
    width: 140px;
    height: 40px;
  }
  /* HOME BANNER END */
  .products-sec h3 {
    font-size: 16px;
    max-width: 90%;
    padding: 0 20px;
  }

  .products-sec .slick-list {
    padding: 0 10% 0 0 !important;
  }
  .products-sec .slick-slide {
    margin-left: 20px;
  }
  /* FOOTER START */

  .footer-heading {
    margin-bottom: 18px;
    margin-top: 45px;
  }
  .link-list {
    margin-top: 18px;
    margin-bottom: 0;
  }
  .main-footer .container {
    padding-top: 0;
    padding-bottom: 0;
  }
  .social-links-wrapper {
    margin-top: 15px;
  }
  /* FOOTER END */
  /* .about-sec h5 {
    font-size: 20px;
  }
  .about-sec h4 {
    font-size: 35px;
  } */
  .best-product-sec:before {
    width: calc(100% - 45px);
  }
  .leader-sec h5 {
    font-size: 16px;
  }
  .leader-cards-wrapper {
    flex-wrap: wrap;
    justify-content: center;
  }
  /* .sustainable-content {
    max-width: 60%;
    padding: 25px 30px;
    bottom: -108px;
  } */

  .sustainable-sec h4 {
    font-size: 45px;
  }
  .sustainable-content h3 {
    font-size: 36px;
  }
  .sustainable-sec p {
    font-size: 18px;
  }

  .sustainable-sec {
    padding: 50px 0;
    margin-bottom: 80px;
  }
  .leader-cards-wrapper h3 {
    font-size: 16px;
  }
  .leader-card {
    margin-bottom: 15px;
  }
  .contact-list {
    flex-direction: column;
    gap: 10px;
  }
  .contact-item p {
    margin-bottom: 0;
  }
  .contact-item {
    width: 100%;
  }
  .ut-card-info {
    padding: 0 2% 0 5%;
  }
  .ut-vertical-sec .ut-card-heading {
    font-size: 22px;
  }
  .ut-vertical-sec .ut-card-content {
    font-size: 14px;
    margin-left: 0;
  }
  .read-more-btn {
    height: 40px;
  }
  .ut-vertical-sec h4,
  .ut-vertical-sec h5 {
    max-width: calc(100% - 25px);
  }
  .val-col-content h5 {
    height: 120px;
    font-size: 16px;
  }

  .val-txt {
    padding: 0 24px;
  }

  .val-col h3 {
    font-size: 55px;
    padding-left: 12px;
  }

  .val-col-content h4 {
    font-size: 28px;
  }

  .val-col {
    height: 350px;
    width: calc(33.33% - 0.6px);
  }

  .values-wrapper {
    row-gap: 1px;
  }
  .mission-vision-wrapper {
    width: calc(100% - 50px);
  }

  .mission-col,
  .vision-col {
    padding: 50px 28px;
  }

  .mission-sec h3 {
    font-size: 55px;
    margin-bottom: 0;
  }

  .mission-sec h4 {
    font-size: 22px;
  }
  .about-banner h1 {
    font-size: 45px;
  }

  .about-banner h3 {
    line-height: 1.2;
  }

  .about-banner-content {
    padding-left: 20px;
    max-width: 85%;
  }
  .tab-inner-content {
    padding: 0 35px 35px;
  }
  .department-sec .nav-pills .nav-link {
    font-size: 20px;
    padding-left: 15px;
  }
  .department-sec #v-pills-tab {
    min-width: 306px;
  }
  .tab-inner-content h4 {
    font-size: 32px;
  }
  .tab-inner-content p {
    font-size: 22px;
  }
  .infra-tabs {
    max-width: calc(100% - 90px);
  }
  .infra-tabs {
    max-width: calc(100% - 45px);
  }

  .department-sec .nav-pills .nav-link {
    font-size: 18px;
  }

  .tab-inner-content {
    padding: 0 20px 20px;
  }

  .tab-inner-content p {
    font-size: 16px;
  }

  .tab-inner-content h4 {
    font-size: 26px;
    max-width: 203px;
  }
  .department-sec h3 {
    font-size: 16px;
  }
  .department-sec h3 {
    margin-bottom: 30px;
  }
  .product-left-content:before {
    content: none;
  }

  .product-content-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .product-item-desc-sec {
    flex-direction: column;
    align-items: flex-start;
  }

  .product-content-wrapper {
    width: 100%;
    position: relative;
    padding-bottom: 0;
    margin-bottom: 15px;
  }

  .product-img {
    width: 100%;
  }

  .product-content-wrapper:before {
    content: "";
    background: #a8906e;
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 100%;
  }

  .product-item-desc-sec h4 {
    margin-bottom: 0;
  }

  .product-right-content {
    flex-direction: column-reverse;
  }

  .product-right-content:before {
    content: none;
  }

  .product-right-content .product-content-wrapper {
    padding-left: 20px;
    padding-right: 19px;
  }
  .infra-right-sec .infra-content-wrapper {
    right: auto;
    max-width: initial;
    left: 0;
    transform: none;
  }

  .infra-right-sec {
    margin-right: 0;
  }

  .infra-content-wrapper {
    top: 0;
    width: 65%;
    max-width: none;
    left: 0;
    position: relative;
    transform: none;
    margin-left: auto;
    margin-right: auto;
    padding: 40px;
    height: fit-content;
    margin-top: -20%;
  }
  .infra-left-sec img,
  .infra-right-sec img {
    max-height: 440px;
  }
  .infra-left-sec,
  .infra-right-sec {
    margin-bottom: 40px;
    margin-left: 0;
  }
  .leader-profile-sec .container {
    flex-direction: column;
    align-items: center;
  }
  section.leader-profile-sec img {
    height: auto;
  }
  .leader-profile-sec .container img {
    width: 100%;
    margin-bottom: 10px;
  }

  .leader-info {
    text-align: center;
    padding-left: 0;
  }

  .leader-profile-sec h2 {
    font-size: 30px;
  }

  .leader-profile-sec span {
    font-size: 16px;
  }

  .leader-profile-sec h3 {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .leader-profile-sec h4 {
    font-size: 16px;
  }

  .right-side-profile .container .leader-info {
    order: 2;
    padding-right: 0;
  }
  .leader-desc-sec h4 {
    font-size: 20px;
  }
  .form-wrapper {
    flex-direction: column;
  }
  .location-list {
    width: 100%;
    order: 2;
  }
  .form-container {
    width: 100%;
    order: 1;
  }
  .location-list {
    padding: 15px 0 0;
  }
  .location-list ul {
    padding: 0;
  }
  .form-wrapper h3 {
    font-size: 18px;
  }
  .form-wrapper p {
    font-size: 16px;
  }

  /* .link-list {
    display: block;
  } */
  .press-detail-sec h1 {
    font-size: 26px;
  }
  .press-detail-sec p {
    font-size: 16px;
    line-height: 1.2;
  }
  .press-detail-sec iframe {
    height: 350px;
    margin: 25px 0;
  }
  .sustainable-content {
    margin-left: 0;
    max-width: 100%;
  }
  .co-position {
    font-size: 18px;
  }

  .co-info {
    flex-direction: column;
    gap: 0;
    row-gap: 5px;
    margin-top: 10px;
    text-align: left;
  }

  .job-desc {
    font-size: 14px;
  }
  .job-accordion-btn::after {
    top: 0;
    transform: translateY(0);
  }
  .job-accordion-btn.collapsed::after {
    transform: rotate(-90deg);
  }
  .single-form-row {
    flex-direction: column;
    gap: 0 !important;
  }
  #mainGallerySlider img {
    height: 450px;
  }
  .min-w-330-resp {
    min-width: 100%;
  }
}

@media (max-width: 767px) {
  .pt-sec {
    padding-top: 70px;
  }
  .pb-sec {
    padding-bottom: 70px;
  }
  .mt-sec {
    margin-top: 70px;
  }
  .mb-sec {
    margin-bottom: 70px;
  }
  .sec-heading {
    font-size: 35px;
  }
  /* HOME BANNER START */
  .banner-content {
    padding-left: 8px;
    z-index: 1;
  }

  .best-product-sec .carousel-item:before {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.67) 0%, rgba(16, 24, 40, 0.08) 66.21%);
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  /* HOME BANNER END */
  .slider-item img {
    height: 270px;
  }
  .product-slider .slick-prev.slick-arrow {
    left: 40px;
  }
  /* FOOTER START */
  .footer-top .container {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    gap: 15px;
  }
  .footer-top h3 {
    font-size: 20px;
    max-width: 290px;
  }
  /* FOOTER END */
  .about-sec h4 {
    font-size: 30px;
  }
  .about-content {
    text-align: center;
  }
  .best-product-sec h4 {
    max-width: calc(100% - 130px);
    font-size: 14px;
  }
  .best-prod-name a {
    height: 70px;
    width: 300px;
    font-size: 22px;
  }

  .best-product-sec .carousel-control-next {
    height: 70px;
    left: 360px;
    width: 60px;
  }

  .best-product-sec .carousel-control-prev {
    height: 70px;
    width: 60px;
  }

  .sustainable-sec li {
    margin-bottom: 10px;
  }

  .sustainable-content {
    position: relative;
    top: 0;
    max-width: 100%;
    margin-bottom: -110px;
    padding: 74px 30px 85px;
  }

  .sustainable-content p {
    font-size: 17px;
  }

  .ut-card img {
    height: auto;
  }

  .ut-vertical-sec {
    margin: 0;
  }

  .ut-card:hover .ut-card-img-wrapper {
    transform: none;
  }

  .ut-card:hover .ut-card-heading {
    text-align: left;
    width: 100%;
    margin: 0;
  }

  .ut-card:hover .read-more-btn {
    margin: 20px 0px 10px;
  }

  .ut-vertical-sec .ut-card-heading {
    max-width: 95px;
    font-size: 22px;
    line-height: 1;
  }

  .read-more-btn {
    width: 100px;
    height: 35px;
  }

  .ut-vertical-sec .ut-card-content {
    font-size: 14px;
    margin: 0;
    max-width: 275px;
  }
  .ut-cards-wrapper {
    margin-top: 30px;
  }
  .ut-vertical-sec h4,
  .ut-vertical-sec h5 {
    font-size: 14px;
  }
  .ut-vertical-sec {
    padding-top: 30px;
  }
  .tooltip-wrapper h2 {
    font-size: 14px;
    margin: 5px 0;
  }
  .tooltip-wrapper h3 {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .tooltip-wrapper {
    min-width: 120px;
    bottom: 35px;
  }
  .point:hover .tooltip-wrapper {
    min-width: 120px;
    padding: 5px;
  }
  .dot img {
    width: 20px;
    height: 20px;
  }

  .point {
    width: 30px;
  }
  .best-product-sec:before {
    width: calc(100% - 15px);
  }
  .best-product-sec h4 {
    max-width: calc(100% - 90px);
  }
  .ut-cards-wrapper {
    flex-wrap: wrap;
    flex-direction: row;
    margin: 30px 10px 0;
  }
  .ut-card {
    width: 50%;
  }
  .ut-vertical-sec:before {
    height: 45%;
  }
  .val-col {
    width: calc(50% - 0.5px);
  }
  .about-banner h1 {
    font-size: 35px;
  }

  .about-banner-content {
    padding-left: 0;
    max-width: 85%;
  }
  .about-banner {
    max-height: none;
    min-height: 360px;
  }
  /**************** DEPARTMENT RESPONSIVENESS ****************/

  .infra-tabs {
    flex-direction: column;
  }

  .department-sec #v-pills-tab {
    flex-direction: row !important;
    flex-wrap: nowrap;
    gap: 10px;
    overflow-x: auto;
  }

  .department-sec .nav-pills .nav-link {
    min-width: fit-content !important;
  }
  .department-sec .nav-pills .nav-link {
    height: 30px;
    font-size: 14px;
    padding: 0 10px;
    margin-bottom: 10px;
    font-weight: 600;
    line-height: 34px;
    border-radius: 2px;
  }

  .department-sec #v-pills-tab {
    margin-bottom: 20px;
    position: relative;
  }

  .infra-tabs {
    max-width: calc(100% - 20px);
    margin: 0 auto;
    position: relative;
  }

  .department-sec .nav-pills .nav-link.active,
  .department-sec .nav-pills .nav-link.hover {
    background-color: #76511c;
  }

  .department-sec .infra-tabs:before {
    content: "";
    background: linear-gradient(90deg, #ffffff 11.46%, rgba(255, 255, 255, 0) 100%);
    width: 62px;
    height: 30px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }

  .department-sec .infra-tabs.showleftfadeEffect:before {
    z-index: 1;
  }

  .department-sec .infra-tabs:after {
    content: "";
    background: linear-gradient(270deg, #ffffff 11.46%, rgba(255, 255, 255, 0) 100%);
    width: 50px;
    height: 30px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  .department-sec .infra-tabs.showRightfadeEffect:after {
    z-index: 1;
  }
  .department-sec .tab-pane {
    min-height: 340px;
  }

  .infra-btn {
    width: 100px;
    height: 35px;
    font-size: 14px;
    font-weight: 600;
  }
  .product-item-desc-sec h2 {
    font-size: 30px;
  }

  .product-item-desc-sec h3 {
    font-size: 20px;
  }

  .product-item-desc-sec h4 {
    font-size: 16px;
    line-height: 1.2;
  }
  .infra-content-wrapper p {
    font-size: 18px;
  }
  .infra-content-wrapper h3 {
    font-size: 35px;
  }
  .form-container {
    padding: 20px;
  }
  .form-wrapper input {
    height: 30px;
  }
  .form-wrapper input,
  .form-wrapper textarea {
    font-size: 14px;
  }
  .form-submit-btn {
    width: 100%;
    font-size: 16px;
    height: 35px;
    margin-top: 20px;
  }
  .two-col {
    display: block;
  }
  .two-col .link-list:not(:first-child) {
    margin-top: 5px;
  }
  .link-list {
    gap: 5px;
  }
  .ceo-message-img-wrapper {
    width: 100%;
  }
  .benefit-card {
    width: 50%;
  }
  .life-utopia-cards-row {
    flex-wrap: wrap;
    row-gap: 50px;
    justify-content: center;
    justify-content: space-between;
  }

  .life-utopia-card {
    width: 100%;
  }

  .life-utopia-cards-row {
    row-gap: 25px;
  }

  .life-utopia-card:hover .ut-life-img-wrapper {
    transform: none;
  }
  .career-form-sec .form-container {
    padding: 0;
  }
  .select2-container {
    width: 100% !important;
  }
  div#fileName {
    width: 100%;
    text-align: center;
  }
  .file-upload .file-select .file-select-name {
    line-height: normal;
    margin-top: 10px;
  }
  .file-upload .file-select {
    height: auto;
  }
}
@media (max-width: 575px) {
  .pt-sec {
    padding-top: 60px;
  }
  .pb-sec {
    padding-bottom: 60px;
  }
  .mt-sec {
    margin-top: 60px;
  }
  .mb-sec {
    margin-bottom: 60px;
  }
  .sec-heading {
    font-size: 25px;
  }
  /* HEADER START */

  header nav.navbar {
    height: 50px;
    padding: 0;
  }
  header .navbar-brand img {
    width: 115px;
  }
  header .navbar-brand {
    padding: 0;
    margin: 0;
  }
  .bar1,
  .bar2,
  .bar3 {
    width: 25px;
    height: 4px;
    margin: 3px 0;
  }
  .change .bar1 {
    transform: translate(0, 5px) rotate(-45deg);
  }
  .change .bar3 {
    transform: translate(0, -9px) rotate(45deg);
  }
  header #navbarSupportedContent {
    top: 47px;
  }
  header .navbar-nav {
    padding: 15px;
  }
  header .nav-link {
    font-size: 14px;
    padding: 5px 0;
  }
  header li.nav-item {
    padding-left: 12px;
  }
  /* HEADER END */
  /* HOME BANNER START */
  .banner-content h1 {
    font-size: 25px;
    max-width: 240px;
  }
  .banner-content h3 {
    max-width: 415px;
    font-size: 13px;
    line-height: 16px;
    margin-top: 10px;
  }
  .banner-btn {
    margin-top: 15px;
    width: 120px;
    height: 30px;
    font-size: 12px;
  }
  /* HOME BANNER END */
  .products-sec .slick-list {
    padding: 0 !important;
  }
  .products-sec .slick-slide {
    margin: 0 5px;
  }
  .product-slider .slick-prev.slick-arrow {
    left: 20px;
    opacity: 0.8;
  }
  .product-slider .slick-next.slick-arrow {
    transform: translate(0%, -50%);
    right: 5px;
    opacity: 0.8;
  }
  .product-slider .slick-prev,
  .product-slider .slick-next {
    width: 25px;
    height: 25px;
  }
  .product-slider .slick-arrow::before {
    font-size: 14px;
  }
  .product-name {
    bottom: 20px;
  }
  .about-sec h4 {
    font-size: 35px;
  }
  .best-prod-name {
    left: 45px;
  }

  .best-prod-name a {
    height: 50px;
    width: 210px;
    font-size: 16px;
  }

  .best-product-sec .carousel-control-next {
    height: 50px;
    left: 255px;
    width: 45px;
  }

  .best-product-sec .carousel-control-prev {
    height: 50px;
    width: 45px;
  }
  .best-product-sec {
    padding-top: 30px;
  }
  .leader-cards-wrapper h3 {
    font-size: 16px;
    min-height: 50px;
  }
  .leader-sec h5 {
    font-size: 14px;
  }
  .press-blog-sec h5 {
    font-size: 14px;
  }
  .leader-cards-wrapper img {
    height: 190px;
  }
  .leader-cards-wrapper h3 {
    min-height: 35px;
    font-size: 14px;
  }

  .ut-vertical-sec .ut-card-content {
    font-size: 14px;
  }
  .ut-card:hover .read-more-btn {
    margin: 8px 0px 5px;
  }
  .read-more-btn {
    font-size: 14px;
    width: 80px;
    height: 30px;
  }
  .ut-cards-wrapper {
    flex-direction: column;
  }
  .ut-card {
    width: calc(100% - 25px);
    margin: 0 auto;
  }
  .main-footer {
    padding-bottom: 15px;
  }
  .val-col {
    width: 100%;
    background-position: top;
  }
  .val-col-content h5 {
    height: auto;
    margin-bottom: 15px;
  }
  .mission-vision-wrapper {
    width: calc(100% - 50px);
  }

  .mission-col,
  .vision-col {
    padding: 50px 28px;
  }

  .mission-sec h3 {
    font-size: 55px;
    margin-bottom: 0;
  }

  .mission-sec h4 {
    font-size: 18px;
  }

  .mission-vision-wrapper {
    width: 100%;
    flex-direction: column;
    gap: 1px;
  }

  .mission-col,
  .vision-col {
    width: 100%;
    text-align: center;
  }
  .val-col.val-col-1 {
    height: auto;
    background-color: transparent;
    margin-bottom: 15px;
  }

  .val-col h3 {
    font-size: 22px;
  }
  .about-sec p,
  .about-sec h6 {
    font-size: 14px;
  }
  .department-sec h3 {
    font-size: 14px;
  }
  .department-sec h3 {
    margin-bottom: 15px;
  }
  .department-sec h2 {
    max-width: 280px;
  }
  .product-item-desc-sec h2 {
    font-size: 25px;
  }

  .product-item-desc-sec h3 {
    font-size: 16px;
  }

  .product-item-desc-sec h4 {
    font-size: 14px;
    line-height: 1.2;
  }
  .about-banner h1 {
    font-size: 28px;
  }
  .about-banner h3 {
    font-size: 14px;
  }
  .about-banner-content {
    max-width: 95%;
  }
  .infra-content-wrapper {
    padding: 20px;
    width: 92%;
  }
  .infra-content-wrapper p {
    font-size: 15px;
  }
  .infra-content-wrapper h3 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .leader-profile-sec h2 {
    font-size: 25px;
  }

  .leader-profile-sec span {
    font-size: 14px;
  }

  .leader-profile-sec h3 {
    font-size: 20px;
  }

  .leader-profile-sec h4 {
    font-size: 14px;
  }
  .leader-desc-sec h4 {
    font-size: 16px;
  }
  .map-sec h5 {
    font-size: 14px;
  }
  .profile-card {
    height: 365px;
    max-width: 400px;
    margin: 0 auto;
  }
  .leader-profile-sec {
    margin-bottom: 25px;
  }
  .inner-content {
    flex-direction: column;
    padding: 2px 0;
    gap: 5px;
  }

  button.btn.cookie-btn {
    width: 100%;
  }

  .cookie-content p {
    margin: 0;
  }
  .benefit-card h5 {
    font-size: 16px;
  }

  .life-utopia-cards-row h3 {
    font-size: 18px;
  }

  .life-info-btn {
    height: 40px;
    margin-top: 18px;
  }
  .ut-life-img-wrapper {
    max-height: 435px;
    overflow-y: hidden;
  }
  #mainGallerySlider img {
    height: 225px;
  }
  #previewGallerySlider .slick-track .slick-slide img {
    width: 100px !important;
    object-fit: cover;
    max-height: 75px !important;
    min-height: 75px;
  }
  .csr-sec .product-content-wrapper h4,
  .tcf-main li {
    font-size: 14px;
  }
  .job-listing-btn {
    font-size: 14px !important;
    font-weight: 500 !important;
    margin-top: 30px !important;
    height: 40px;
  }
}

@media (max-width: 480px) {
  .pt-sec {
    padding-top: 30px;
  }
  .pb-sec {
    padding-bottom: 30px;
  }
  .mt-sec {
    margin-top: 30px;
  }
  .mb-sec {
    margin-bottom: 30px;
  }
  .sec-heading {
    font-size: 22px;
  }
  .banner-content h1 {
    font-size: 19px;
    max-width: 200px;
  }
  .banner-content h3 {
    max-width: 80%;
    font-size: 12px;
    line-height: 1.1;
    min-width: 190px;
  }
  .banner-btn {
    width: 100px;
    height: 28px;
    font-size: 12px;
    margin-top: 8px;
  }
  .best-product-sec .carousel-item {
    min-height: 300px;
  }

  .best-product-sec .carousel-item img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    width: 100%;

    object-fit: cover;
  }
  .products-sec h3 {
    font-size: 14px;
    max-width: 90%;
  }
  .about-sec h4 {
    font-size: 22px;
  }
  .about-sec h6 {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .about-sec p {
    font-size: 14px;
  }
  .sustainable-content h3 {
    font-size: 35px;
  }
  .sustainable-sec h4 {
    font-size: 30px;
    line-height: 1.2;
  }
  .sustainable-sec p {
    font-size: 14px;
  }
  .press-blog-card a {
    font-size: 12px;
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .footer-top {
    min-height: 160px;
  }
  .footer-top .container a {
    font-size: 14px;
    width: 190px;
    height: 45px;
  }
  .footer-top h3 {
    font-size: 18px;
  }
  .leader-desc-sec h4 {
    font-size: 14px;
  }
  .leader-profile-sec h3 {
    font-size: 16px;
  }
  .map-sec h5 {
    font-size: 14px;
  }
  .about-sec p {
    max-width: 90%;
    margin: 0px auto 10px;
  }
  .infra-content-wrapper ul li {
    margin-bottom: 10px;
  }
}
